.rainbow {
  position: relative;
  width: auto;
  height: auto;
  background: linear-gradient(0deg, #000, #272727);
  border-radius: 16px;
}

.rainbow:before,
.rainbow:after {
  content: "";
  position: absolute;
  left: -2px;
  top: -2px;
  border-radius: 16px;
  background: linear-gradient(
    45deg,
    #fb0094,
    #0000ff,
    #00ff00,
    #ffff00,
    #ff0000,
    #fb0094,
    #0000ff,
    #00ff00,
    #ffff00,
    #ff0000
  );
  background-size: 400%;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  z-index: -1;
  animation: steam 20s linear infinite;
}

@keyframes steam {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.rainbow:after {
  filter: blur(50px);
}

.game-border {
  border-radius: 16px;
  border: 2px solid white;
}

.selected-category {
  position: relative;
  margin: auto;
  width: 120px;
  line-height: 64px;
  text-align: center;
  color: #fff;
  font-size: 20px;
  border: 2px solid gold;
  border-radius: 10px;
  background: gold;
  transition: all 1s;
  cursor: pointer;
}
.selected-category:hover {
  filter: contrast(1.1);
}
.selected-category:active {
  filter: contrast(0.9);
}
.selected-category::before,
.selected-category::after {
  content: "";
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  border: 2px solid rgb(159, 241, 142);
  transition: all 0.5s;
  -webkit-animation: clippath 3s infinite linear;
  animation: clippath 3s infinite linear;
  border-radius: 10px;
}
.selected-category::after {
  -webkit-animation: clippath 3s infinite -1.5s linear;
  animation: clippath 3s infinite -1.5s linear;
}

@-webkit-keyframes clippath {
  0%,
  100% {
    -webkit-clip-path: inset(0 0 98% 0);
    clip-path: inset(0 0 98% 0);
  }
  25% {
    -webkit-clip-path: inset(0 98% 0 0);
    clip-path: inset(0 98% 0 0);
  }
  50% {
    -webkit-clip-path: inset(98% 0 0 0);
    clip-path: inset(98% 0 0 0);
  }
  75% {
    -webkit-clip-path: inset(0 0 0 98%);
    clip-path: inset(0 0 0 98%);
  }
}

@keyframes clippath {
  0%,
  100% {
    -webkit-clip-path: inset(0 0 98% 0);
    clip-path: inset(0 0 98% 0);
  }
  25% {
    -webkit-clip-path: inset(0 98% 0 0);
    clip-path: inset(0 98% 0 0);
  }
  50% {
    -webkit-clip-path: inset(98% 0 0 0);
    clip-path: inset(98% 0 0 0);
  }
  75% {
    -webkit-clip-path: inset(0 0 0 98%);
    clip-path: inset(0 0 0 98%);
  }
}

.gradient-border {
  --borderWidth: 3px;
  background: #1d1f20;
  position: relative;
  border-radius: 0;
}
.gradient-border:after {
  content: "";
  position: absolute;
  top: calc(-1 * var(--borderWidth));
  left: calc(-1 * var(--borderWidth));
  height: calc(100% + var(--borderWidth) * 2);
  width: calc(100% + var(--borderWidth) * 2);
  background: linear-gradient(
    60deg,
    #a37727,
    #ffffd9,
    #a37727,
    #ffffd9,
    #a37727,
    #ffffd9,
    #a37727,
    #ffffd9
  );
  border-radius: 0;
  z-index: -1;
  -webkit-animation: animatedgradient 3s ease alternate infinite;
  animation: animatedgradient 3s ease alternate infinite;
  background-size: 300% 300%;
}

@-webkit-keyframes animatedgradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes animatedgradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/** ----------------- glowing game selection logo ---------------- **/

/* star shaped shine pattern */
.shine {
  position: absolute;
  top: 3px;
  left: 38px;
  width: 1px;
  height: 16px;
  background: #fff;
  transform: rotate(15deg);
  box-shadow: 0 0 8px rgba(255, 255, 255, 0.75);
  animation: shine 4s infinite;
}
.shine:before,
.shine:after {
  content: "";
  display: block;
  position: absolute;
}
.shine:before {
  top: 2px;
  left: -2px;
  width: 1px;
  height: 16px;
  background: #fff;
  transform: rotate(-90deg);
  box-shadow: 0 0 8px rgba(255, 255, 255, 0.75);
}
.shine:after {
  top: 6px;
  left: -2px;
  width: 5px;
  height: 5px;
  background: #fff;
  transform: rotate(45deg);
  box-shadow: 0 0 8px 5px rgba(255, 255, 255, 0.75);
}
/* reflective line */
.light {
  position: absolute;
  width: 180px;
  height: 180px;
  border-radius: 100%;
  overflow: hidden;
  z-index: 2;
}
.light:before {
  content: "";
  display: block;
  position: absolute;
  width: 2px;
  height: 180px;
  top: -20%;
  left: 0;
  transform: rotate(45deg);
  background: rgba(251, 202, 113, 0.5);
  animation: sheen2 4s infinite;
}
/* animates orb glow */
@keyframes orb {
  0%,
  100% {
    box-shadow: 0 0 5px 2px #8fffcf;
  }
  50% {
    box-shadow: 0 0 24px 4px #8fffcf;
  }
}
/* animates ticket orb glow */
@keyframes ticketOrb {
  0%,
  100% {
    box-shadow: 0 0 2px 0 #fff;
  }
  50% {
    box-shadow: 0 0 10px 0 #fff;
  }
}
/* rotates shine */
@keyframes shine {
  0% {
    opacity: 0;
  }
  34% {
    opacity: 0;
    transform: rotate(15deg);
  }
  40% {
    opacity: 0.6;
    transform: rotate(620deg);
  }
  48%,
  100% {
    opacity: 0;
    transform: rotate(635deg);
  }
}
/* moves light reflection */
@keyframes sheen2 {
  0%,
  100% {
    top: -75%;
    left: 15%;
    opacity: 0;
    width: 2px;
  }
  1% {
    opacity: 1;
  }
  10% {
    width: 24px;
  }
  11%,
  18% {
    top: 75%;
    left: 50%;
    width: 2px;
    opacity: 0;
  }
  20% {
    top: -75%;
    left: 15%;
    opacity: 0;
    width: 1px;
  }
  21% {
    opacity: 0.75;
  }
  30% {
    width: 6px;
  }
  31%,
  98% {
    top: 75%;
    left: 50%;
    width: 2px;
    opacity: 0;
  }
}

.vip-logo {
  border-radius: 50%;
  box-shadow: 0px 0px 9px 4px #fbca71;
  animation: glow 1s linear infinite alternate;
  height: 120px;
  width: 120px;
  position: absolute;
  z-index: 1;
  top: 0px;
  left: 0px;
  right: 0;
  bottom: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
}

@keyframes glow {
  to {
    box-shadow: 0px 0px 30px 35px #fbca71;
  }
}

.game-logo {
  animation: logoScale 0.5s ease-in-out infinite alternate;
  -webkit-animation: logoScale 1s ease-in-out infinite alternate;
}

@keyframes logoScale {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.02);
  }
}

@media screen and (max-width: 570px) {
  .game-logo {
    width: 25vw !important;
  }
  .vip-logo {
    height: 20vw;
    width: 20vw;
  }

  .game-card-header > p {
    font-size: 5.6vw !important;
  }

  .game-card-header > button > p {
    font-size: 4.8vw;
  }

  .game-card-header > button > img {
    height: 8.1vw !important;
  }

  .game-card-footer > div > div > div > p,
  .game-card-footer > div > div > p {
    font-size: 4.21vw !important;
  }

  .game-card-footer > div > div > div > img {
    height: 4vw !important;
    width: 4vw !important;
  }

  .game-card-footer > div > div > div > p {
    margin-left: 2.5vw !important;
  }

  .game-card-footer {
    padding: 2.77vw !important;
  }

  .card-logo-container {
    margin-top: -20.8vw;
  }

  .game-card-container {
    margin-right: -12.5vw !important;
  }

  @keyframes glow {
    to {
      box-shadow: 0px 0px 6vw 3vw #fbca71;
    }
  }

  .light {
    width: 25vw !important;
    height: 25vw !important;
  }

  .game-selection-card > p:first-child {
    font-size: 8.85vw;
    margin-top: 5.5vw;
    margin-bottom: 2.8vw;
  }

  .game-selection-subtitle:nth-child(2) {
    font-size: 4.29vw;
    margin-bottom: 11.5vw;
  }

  .custom-tabs > div > div > button {
    margin-right: 0px !important;
    min-width: 60px;
  }
}

:root {
  --diamond-logo-height: 65px;
  --diamond-logo-width: 66px;
}

.diamond-logo-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: var(--diamond-logo-width) !important;
  width: var(--diamond-logo-width) !important;
  margin-right: calc(-0.5 * var(--diamond-logo-width)) !important;
}

.joinnow-button > button > img {
  background: transparent;
  height: calc(var(--diamond-logo-height) / 2) !important;
  width: auto;
}

.diamond-logo-container > img {
  height: var(--diamond-logo-height);
  width: var(--diamond-logo-width);
}

.lotto-card {
  flex-basis: calc((var(--diamond-logo-width) / 2 - 7px) + 75%) !important;
  max-width: 100% !important;
}

.animated-digits {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeOutLeft {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-480px);
  }
}

@keyframes fadeOutLeft {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(-480px);
  }
}

.fadeOutLeft {
  -webkit-animation-name: fadeOutLeft;
  animation-name: fadeOutLeft;
}

@media screen and (max-width: 400px) {
  .mob-keypads {
    min-width: 50px !important;
  }
}

.keypads {
  display: block !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#gradient {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 80px 10vw 0 0;
  border-color: black #F0C62E transparent transparent;
  transform: scale(1.0001);
}

.landing-menu {
  cursor: pointer;
}

.landing-menu:hover {
  color: #be7b00 !important;
}

.landing-menu-selected {
  color: #be7b00 !important;
  cursor: pointer;
}

@media screen and (max-width: 899px) and (min-width: 600px) {
  .live-logo {
    width: 60% !important;
  }
}

@media screen and (max-width: 599px) {
  .live-logo {
    width: 50% !important;
  }

  .myanmar-circle-logo {
    width: 10px !important;
    height: 10px !important;
  }

  .jackpot-logo {
    width: 100% !important;
  }

  .past-results {
    width: 100% !important;
  }

  #gradient {
    border-width: 51px 10vw 0 0 !important;
  }

  .landing-main-banner {
    padding-top: 50px !important;
  }

  .live-rich-lotto {
    width: 200px !important;
  }

  .landing-app-logo {
    width: 65px !important;
  }
}
